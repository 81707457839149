.staticRange {
  background: #ffffff;
  border: none;
  padding: 14px 20px;
  text-align: left;
  color: black;
  border-bottom: 1px solid #ecf0f5;
  outline: none;
}

.staticRange:hover,
.staticRange:focus {
  background: #fdeeda;
}

#selection .rdrDateDisplayItem {
  color: var(--major-blue) !important;
  font-size: 12px;
}

#selection .rdrDateDisplayItemActive input::placeholder {
  color: var(--major-blue) !important;
  opacity: 0.8;
}

#selection.rdrDateDisplay::before {
  color: var(--major-blue) !important;
  font-size: 12px;
  font-weight: bold;
  padding-right: 0px !important;
  width: 100px;
}

#selection .rdrDateDisplayItem input {
  color: var(--major-blue);
}

#compare .rdrDateDisplayItem {
  color: var(--major-orange) !important;
  font-size: 12px;
}

#compare .rdrDateDisplayItemActive input::placeholder {
  color: var(--major-orange) !important;
  opacity: 0.8;
}

#compare.rdrDateDisplay::before {
  color: var(--major-orange) !important;
  font-size: 12px;
  font-weight: bold;
  padding-right: 0px !important;
  width: 100px;
}

#compare .rdrDateDisplayItem input {
  color: var(--major-orange);
}

.rdrDateDisplay {
  margin: 12px;
}

.rdrDateDisplayWrapper {
  background-color: #f7f9fc;
  padding: 8px 15px;
}

.rdrMonth {
  width: 100%;
}

.rdrDateRangeWrapper {
  background: #f7f9fc;
}

.rdrMonthAndYearWrapper {
  background: white;
  margin: 10px;
  padding: 0px;
  height: 36px;
}

.rdrMonthAndYearPickers select {
  padding: 5px 30px 5px 10px;
}

.rdrMonths {
  background: white;
  margin: 6px 10px 10px 10px;
}

.static-range-row {
  margin: 0;
}

.small-switch > input {
  width: 28px !important;
  height: 14px !important;
  margin-left: 0px !important;
  box-shadow: none !important;
  outline: none !important;
}

.small-switch > input:checked {
  background-color: var(--major-blue) !important;
  border-color: var(--major-blue) !important;
}

.rdrDateDisplayItem {
  height: 32px;
}

#selection-dropdown {
  background: var(--major-blue);
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  box-shadow: none !important;
  outline: none !important;
}
