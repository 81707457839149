:root {
  --major-blue: #0068ff;
  --major-orange: #f3971a;
  --back-dark: #062238;
  --major-disabled: #d9d9d9;
  --fore-light: #ffffff;
  --fore-dark: #04223A;
}

.ag-theme-alpine {
  --ag-header-foreground-color: var(--back-dark);
  --ag-header-background-color: #ecf0f5;
  --ag-header-cell-moving-background-color: #f6faff;
  --ag-border-radius: 5px;
  --ag-font-family: var(--bs-body-font-family);
}

.ag-theme-alpine .ag-header-group-cell {
  font-size: 12.6px;
  font-weight: 500;
}
.ag-theme-alpine .ag-header-cell {
  font-size: 12.2px;
  font-weight: 500;
}
.ag-theme-alpine .ag-row,
.ag-theme-alpine-dark .ag-row {
  font-size: 12px;
}

.ag-header-row {
  height: 36px;
}

.ag-header-row-column-group {
  height: 40px;
}

.ag-dnd-ghost {
  visibility: hidden;
}

.ag-column-select-column-drag-handle {
  display: none;
}

.ag-column-select-indent-0 .ag-column-select-column-drag-handle {
  display: block;
}

.ag-side-buttons {
  display: none;
}

.ag-side-bar-left .ag-tool-panel-wrapper {
  border-left: none !important;
  border-right: var(--ag-borders) var(--ag-border-color) !important;
}

.ag-side-bar-right .ag-tool-panel-wrapper {
  border-left: var(--ag-borders) var(--ag-border-color) !important;
  border-right: none !important;
}

.ag-side-bar {
  border-left: none !important;
  border-right: none !important;
}

/* .ag-root-wrapper.ag-layout-normal {
  border-bottom: none;
} */

.ag-floating-bottom {
  background: #ecf0f5;
}

.ag-floating-bottom .ag-row {
  background: #ecf0f5;
}

.ag-row:last-child {
  border-bottom: none;
}

.ag-icon-filter::before {
  content: "";
  background: url("assets/img/icons/dark-filter.svg") center center no-repeat;
  width: 16px;
  height: 16px;
  display: block;
}

.ag-filter-toolpanel-group.ag-has-filter
  > .ag-group-title-bar
  .ag-group-title::after {
  content: "";
  background: url("assets/img/icons/dark-filter.svg") center center no-repeat;
  width: 16px;
  height: 16px;
  margin-left: 6px;
}


.grid-wrapper {
  height: 100%;
  width: 100%;
  min-height: 360px;
}

.grid-cell-link {
  text-decoration: none !important;
  color: var(--major-blue);
}

.ag-checkbox-input-wrapper {
  box-shadow: none !important;
  background-color: transparent;
}

.ag-checkbox-input-wrapper::after {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid #babfc7;
  top: 1.5px !important;
  /* transition: all 180ms linear;
  transition-property: background-color, border-color; */
}

.ag-checkbox-input-wrapper.ag-checked::after {
  content: "";
  background: url("assets/img/icons/check.svg") center center no-repeat;
  background-color: var(--major-blue);
  border-color: var(--major-blue);
}

.ag-checkbox-input-wrapper.ag-indeterminate::after {
  content: "";
  background: url("assets/img/icons/minus.svg") center center no-repeat;
  background-color: #999999;
  border-color: #999999;
}

.ag-checkbox-input-wrapper input {
  cursor: pointer;
}

.filters-button {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  background: var(--major-blue);
  color: var(--fore-light);
  padding: 2px 16px;
  margin-right: 10px;
  height: 34px;
  white-space: nowrap;
  border-radius: 0px 50px 50px 0px;
}

.filter-util-button {
  background: transparent;
  border: 1px solid var(--major-blue);
  border-left: none;
  height: 34px;
  color: var(--major-blue);
  padding: 4px 8px 0px 8px;
  outline: none;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-util-button span {
  font-size: 10.6px;
  height: 11px;
  line-height: 10.6px;
  margin-top: 3px;
}

.filter-util-button-end {
  margin-right: 12px;
  padding-right: 12px;
  border-radius: 0px 50px 50px 0px;
}

.filter-chip {
  border-radius: 50px;
  background: #f7f9fc;
  border: 1px solid #babfc7;
  margin-left: 0.5em;
  margin-top: 4px;
}

.filter-chip > .alert-message {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 12px !important;
  padding-right: 24px !important;
  font-size: 11px;
  line-height: 11px;
  color: #04223a;
}

.filter-chip > .btn-close {
  width: 8px;
  height: 8px;
  padding: 0px !important;
  top: 8px;
  right: 8px;
  background: none;
  opacity: 1;
}

.filter-chip > .btn-close:focus {
  box-shadow: none !important;
  outline: none !important;
}

.filter-chip > .btn-close::before {
  content: "×";
  font-size: 14px;
  color: #04223a;
  position: relative;
  top: -8px;
  opacity: 1;
}

.filter-chip-reset > .btn-close {
  display: none;
}

.filter-chip-reset > .alert-message {
  padding-left: 12px !important;
  padding-right: 12px !important;
  color: #f3971a;
}

.filter-chip-reset {
  background: #fff5e7;
  border: 1px solid #f3971a;
  cursor: pointer;
  margin-left: 0;
}

.page-tabs {
  padding-left: 12px;
  padding-right: 12px;
  z-index: 100;
  
}

.page-tabs .nav-link {
  background: transparent !important;
  color: var(--fore-dark) !important;
  padding: 0.275em 1em !important;
  
}

.page-tabs .nav-link.active {
  color: var(--major-blue) !important;
  font-weight: bold;
  border-bottom: 2px solid var(--major-blue);
}

.secondary-tabs {
  padding-left: 12px;
}

.secondary-tabs .nav-link {
  background: white;
  color: var(--back-dark) !important;
  border-radius: 0px;
  border: 1px solid var(--back-dark) !important;
  border-right: none !important;
  padding: 4px 12px !important;
  font-size: 13.2px;
  height: 32px;
}

.secondary-tabs .nav-link-first {
  border-radius: 50px 0px 0px 50px;
  padding-left: 20px !important;
}

.secondary-tabs .nav-link-last {
  border-radius: 0px 50px 50px 0px;
  border-right: 1px solid var(--back-dark) !important;
  padding-right: 20px !important;
}

.secondary-tabs .nav-link-both {
  border-radius: 50px;
  border-right: 1px solid var(--back-dark) !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.secondary-tabs .nav-link.active {
  background: var(--back-dark) !important;
  color: white !important;
}

.filter-select svg {
  width: 14px;
  height: 14px;
  top: 8px;
  right: 8px;
  position: absolute;
}

.opasity-grid-number-input {
  width: 100%;
  min-width: 0;
  background: transparent;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.opasity-grid-number-input::-webkit-outer-spin-button,
.opasity-grid-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.opasity-grid-circle-button {
  background: transparent;
  border: none;
  border-radius: 50px;
  padding: 0;
  width: 22px;
  height: 22px;
  box-shadow: none !important;
  outline: none !important;
  transition: background 160ms ease-in-out;
}

.opasity-grid-circle-button-cancel:focus,
.opasity-grid-circle-button-cancel:hover {
  background: #fef3e4;
}

.opasity-grid-circle-button-ok:focus,
.opasity-grid-circle-button-ok:hover {
  background: #e0edff;
}

.opasity-grid-circle-button:disabled {
  cursor: default;
  background: transparent;
}

.inline-cell-editing {
  background: white;
  border: 1px solid black !important;
}

.input-group-catalog {
  width: 228px;
}

.search-field-catalog {
  height: 32px !important;
  border-radius: 50px 0px 0px 50px;
  color: #062238;
  font-size: 12px;
}

.search-field-catalog::placeholder {
  color: #babfc7;
}

.search-field-adornment-catalog {
  height: 32px !important;
  border-radius: 0px 50px 50px 0px;
}

.search-field-icon-catalog {
  width: 14px !important;
  height: 14px !important;
  position: relative;
  top: -1px;
  color: #062238;
}

.pagination-btn {
  width: 10px;
  height: 24px;
  border-radius: 12px;
  background: transparent;
  border: none;
  transition: background 160ms ease-in-out;
  outline: none !important;
  box-shadow: none !important;
}

.pagination-btn:hover,
.pagination-btn:active {
  background: #06223810;
}

.pagination-btn-first,
.pagination-btn-last {
  padding: 0px 8px 1px 8px;
}

.pagination-btn-prev {
  padding: 0px 10px 1px 9px;
}

.pagination-btn-next {
  padding: 0px 9px 1px 10px;
}

#page-size-select {
  border: 1px solid #babfc7;
  padding: 1px 25px 1px 8px;
  outline: none !important;
  box-shadow: none !important;
}


#page-size-select-wrapper > select {
  background-image: "black";

}

#page-size-select-wrapper::before {
  content: "";
  position: absolute;
  top: 8px;
  right: 10px;
  width: 6px;
  height: 6px;
  border-right: 1px solid var(--back-dark);
  border-bottom: 1px solid var(--back-dark);
  transform: rotate(45deg);
}

#targeting-add-keyword {
  background: var(--major-blue);
  border-color: var(--major-blue);
  padding: 2px 10px;
  margin-left: 12px;
  margin-right: 12px;
  box-shadow: none !important;
  outline: none !important;
}

footer.footer {
  height: 52px;
  overflow: hidden;
  box-shadow: 0px 1.6px 5px 0px rgba(0, 0, 0, 0.12) inset;
  
}

footer.footer span {
  white-space: nowrap;
  
}

/* Custom tool panel close buttons */

#filter-panel-close,
#column-panel-close {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#filter-panel-close {
  margin-left: 4px;
  margin-right: 8px;
  padding: 2px;
}

#column-panel-close {
  padding: 2px;
}

.ag-column-select-header {
  padding-right: 8px;
}

.ag-column-select-header > .ag-column-select-header-filter-wrapper {
  margin-right: 10px !important;
}

/* Custom ag grid scroll */

.ag-body-vertical-scroll {
  position: absolute;
  right: 1px;
  width: 7px !important;
  max-width: 7px !important;
  min-width: 7px !important;
}

.ag-body-vertical-scroll-viewport {
  width: 7px !important;
  max-width: 7px !important;
  min-width: 7px !important;
}

.ag-body-vertical-scroll-container {
  width: 7px !important;
  max-width: 7px !important;
  min-width: 7px !important;
}

.ag-root-wrapper ::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}

.ag-body-horizontal-scroll {
  height: 9px !important;
  max-height: 9px !important;
  min-height: 9px !important;
  padding: 1px 0px;
  position: absolute;
  bottom: 0px;
}

.ag-body-horizontal-scroll-viewport {
  height: 7px !important;
  max-height: 7px !important;
  min-height: 7px !important;
}

.ag-body-horizontal-scroll-container {
  height: 7px !important;
  max-height: 7px !important;
  min-height: 7px !important;
}

.ag-scroller-corner {
  width: 7px !important;
  max-width: 7px !important;
  min-width: 7px !important;
  height: 7px !important;
  max-height: 7px !important;
  min-height: 7px !important;
}

.ag-body-horizontal-scroll:not(.ag-scrollbar-invisible)
  .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  border-right: none;
}

.ag-root-wrapper ::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
}

.ag-root-wrapper ::-webkit-scrollbar-track {
  background: transparent;
}

.ag-root-wrapper ::-webkit-scrollbar-thumb {
  background: #00000060;
  border-radius: 50px;
  padding: 5px;
}

.ag-pinned-left-floating-bottom::after {
  content: "Total";
  padding-bottom: "30px";
  color: var(--back-dark);
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  left: calc(50% - 15px);
  top: calc(50% - 10px);
}

.cell-align-center {
  padding: 0px !important;
  justify-content: center;
}

.state-toggle {
  width: 22px;
  height: 11px;
  border-radius: 50px;
  position: relative;
  transition: background 200ms;
}

.state-toggle-paused::before,
.state-toggle-enabled::before,
.state-toggle-archived::before {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background: white;
  display: block;
  position: absolute;
  top: 2px;
  transition: left 200ms;
}

.state-toggle-enabled {
  background: var(--major-blue);
}

.state-toggle-paused {
  background: var(--major-disabled);
}

.state-toggle-archived {
  background: var(--major-disabled);
}

.state-toggle-enabled::before {
  left: 13px;
}

.state-toggle-paused::before {
  left: 2px;
}

.state-toggle-archived::before {
  left: 2px;
  content: "×";
  color: var(--major-orange);
  line-height: 8px;
  font-size: 10px;
  padding-left: 0.25px;
  padding-right: 0.25px;
}

.product-name-renderer div span div {
  max-height: 36px;
}

.product-name-expand-overlay {
  background: white;
  border: 1px solid #a7b6c2 !important;
  border-radius: 3px;
  position: absolute;
  padding-right: 20px !important;
  z-index: 1;
  box-shadow: 0px 1.6px 6px 2px rgba(0, 0, 0, 0.12);
  top: -1px;
  left: -1px;
  padding-left: 10px;
  outline: none !important;
}

#product-name-renderer-close {
  border: none;
  background: transparent;
  color: var(--major-orange);
  font-size: 16px;
  line-height: 1px;
  outline: none !important;
  box-shadow: none !important;
  position: absolute;
  top: 2px;
  right: 2px;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 160ms ease-in-out;
}

#product-name-renderer-close:hover {
  background: #fef3e4;
}

.show-more-link:hover {
  text-decoration: underline;
}

.ag-cell:has(#product-name-renderer-close) {
  border-color: transparent !important;
}


.active-tab {
  border : 1px solid black
}

.state-toggle {

  width: 22px;

  height: 11px;

  border-radius: 50px;

  position: relative;

  transition: background 200ms;

}

.state-toggle-paused::before,

.state-toggle-enabled::before,

.state-toggle-archived::before {

  content: "";

  width: 7px;

  height: 7px;

  border-radius: 50px;

  background: white;

  display: block;

  position: absolute;

  top: 2px;

  transition: left 200ms;

}

.state-toggle-enabled {

  background: var(--major-blue);

}

.state-toggle-paused {

  background: var(--major-disabled);

}

.state-toggle-archived {

  background: var(--major-disabled);

}

.state-toggle-enabled::before {

  left: 13px;

}

.state-toggle-paused::before {

  left: 2px;

}

.state-toggle-archived::before {

  left: 2px;

  content: "×";

  color: var(--major-orange);

  line-height: 8px;

  font-size: 10px;

  padding-left: 0.25px;

  padding-right: 0.25px;

}

.ag-header-group-cell-label span {
    cursor: pointer;
}

.ag-icon-right::before, .ag-icon-left::before {
    content: "";
    border: solid;
    display: inline-block;
    padding: 2px;
    position: absolute;
    top: calc(50% - 2px);
    border-width: 0 0.1rem 0.1rem 0;
}

.ag-icon-right::before {
    transform: rotate(-45deg);
    margin-left: 3px;
}

.ag-icon-left::before {
    transform: rotate(135deg);
    margin-left: 5px;
}